<template>
  <div class="chatindex">
    <div class="mainbox base_w">
			<div class="nametitle">{{nametitle}}律师</div>
      <!--已经收到的消息-->
			<div class="messagelist" id="myDIV"  ref="pronbit">
				<div v-for="(item,index) in messageList" :key="index" :id="item.id" >
					<div class="time-lag">
						{{renderMessageDate(item, index)}}
					</div>
					<div class="message-item" :class="item.flow=='user'?'self':''">
						<div class="avatar">
							<img :src="item.userinfo.face" v-if="item.userinfo.face" class="avatarimg">
							<img :src="avatarUrl" v-else class="avatarimg">
						</div>
						<div class="content" :class="item.flow=='user'?'selfdirection':''">
							<div class="message-text" v-if="!['TIMImageElem','TIMSoundElem'].includes(item.type)">
								<div v-if="item.content.title" class="title">{{item.content.title}}</div>
								<div class="flex images" v-if="item.content.images&&item.content.images.length>0">
									<img :src="img" mode="aspectFit" v-for="(img,index) in item.content.images"
										:key="index" @click.stop="predivImage(item.content.images)">
								</div>
								<div class="order-type-msg flex area-between"
									v-if="item.type=='TIMCustomElem'&&item.content.ordertype">
									<div class="order-type-name">{{item.content.ordertype}}</div>
									<div class="order-price">{{item.content.orderPrice}}</div>
								</div>
								<div class="giveReward"
									v-if="item.type=='TIMCustomElem'&&item.content.businessID=='reward'">
									已打赏{{item.content.money}}元</div>
								<div class="evaluate" v-if="item.type=='TIMCustomElem'&&item.content.businessID=='star'">
									<div class="flex row-center">
										<div class="font24" style="margin-right:13px;">评价</div>
										<el-rate v-model="item.content.star"  disabled-void-color="#D8D8D8"
										  disabled></el-rate>
									</div>
								</div>
								<div class="afterSale"
									v-if="item.type=='TIMCustomElem'&&item.content.businessID=='aftersale'">
									<div class="font24 gray">售后原因：{{item.content.reason}}</div>
									<div class="font24 gray" v-if="item.content.desc">简述：{{item.content.desc}}</div>
								</div>
							</div>
							<div class="flex imageInfo" v-if="item.type=='TIMImageElem'">
								<img :src="item.content.imageInfo.url" mode="aspectFit"
									@click.stop="predivImage([item.content.imageInfo.url])"
									:style="{ width: item.content.imageInfo.width + 'px', height: item.content.imageInfo.height+ 'px' }">
							</div>
							<!-- <audioim v-if="item.type=='TIMSoundElem'" :src="item.content.soundurl"
								:duration="item.content.soundSecond"></audioim> -->
							<div class="gray isPeerRead" v-if="item.flow=='user'">{{item.isPeerRead?'已读':'未读'}}
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 发送消息 -->
			<div class="action-box">
				<div class="flexbox imgtop">
					<el-tooltip class="item" effect="light" content="照片" placement="bottom">
						<div class="zhaopian">
							<img src="../../assets/img/imgs.png" mode="" alt="照片" class="zpimg"  >
							<input type="file" name="pic" accept="image/*" id="imagePicker" @change="sendImg" class="inputimg">
						</div>
					</el-tooltip>
					<!-- <el-tooltip class="item" effect="light" content="客服" placement="bottom">
						<img src="../../assets/img/kefu.png" mode="" alt="客服" class="zpimg">
					</el-tooltip> -->
					<el-tooltip class="item" effect="light" content="评价" placement="bottom">
						<img src="../../assets/img/pingjia.png" mode="" alt="评价" class="zpimg"
						 @click.stop="tourl('/pingjia?id='+ orderid +'&lawyerId=' + orderinfo.lsLawyer.id)">
					</el-tooltip>

					<el-tooltip class="item" effect="light" content="申请售后" placement="bottom">
						<img src="../../assets/img/shouhou.png" mode="" alt="申请售后" class="zpimg" @click.stop="tourl('/shouhou?id='+ orderid)">
					</el-tooltip>

					<el-tooltip class="item" effect="light" content="关注律师" placement="bottom" v-if="islike == 0">
						<img src="../../assets/img/xing.png" mode="" alt="关注律师" class="zpimg" @click.stop="ischeck">
					</el-tooltip>
					<el-tooltip class="item" effect="light" content="取消关注律师" placement="bottom" v-else>
						<img src="../../assets/img/xing22.png" mode="" alt="取消关注律师" class="zpimg" @click.stop="ischeck">
					</el-tooltip>

					<el-tooltip class="item" effect="light" content="帮助中心" placement="bottom">
						<img src="../../assets/img/bangzhu.png" mode="" alt="帮助中心" class="zpimg" @click.stop="tourl('/helping')">
					</el-tooltip>
				</div>
				<div class="safeBottom">
					<div class="action-top flex area-between">
					<div class="message-input">
						<!-- <input type="textarea" maxlength="700" :placeholder="'剩余追问'+zhuiwencishu+'次'" v-model="content"> -->
						<el-input
							type="textarea"
							:rows="5"
							:placeholder="'剩余追问'+zhuiwencishu+'次'"
							v-model="content"
							resize="none"
							>
						</el-input>
					</div>
					<div class="send-message-btn" :class="isClick?'noClick':''" @click="sendTextMessage" >发送
					</div>
					</div>
				</div>
			</div>
    </div> 

		<!-- 服务列表弹窗 -->
		<el-dialog
			title=""
			:visible.sync="dialogVisible"
			width="800px"
			:before-close="handleClose"
			center>
			<div class="chooseindex">
				<div @click="toElsePage(item)" class="flex row-center item" v-for="(item,index) in fuwulist"
					:key="index">
					<img :src="item.fwphoto" alt="">
					<div>
						<div class="name-price font28 flex area-between">
							<div class="name">{{item.name}}</div>
							<div class="price">￥{{item.price}}</div>
						</div>
						<!-- <div class="introduce">{{item.interests}}</div> -->
						<div v-html="item.interests"></div>
					</div>
				</div>
  		</div>
 
		</el-dialog>
  </div>
</template>

<script>
import apiUrl from '@/api/main'
import resetTime from "@/util/check.js";
import { mapState } from 'vuex'

import formatTime from '@/util/check.js';
export default {
  components: {},
  data() {
      return {
        messageList: [],
        lawyerphone: '', //律师手机号 ，im userid
				lawyername: '', //律师姓名
        orderid: '', //订单id
        userimID: localStorage.getItem("userimID"), //用户手机号 ，im userid
				userID: localStorage.getItem("userid"), //用户id
				userSig: localStorage.getItem("userSig"),
        order: {
					type: '',
					price: '',
					content: '',
					photos: ''
				},
        zhuiwencishu: 0,
        diahuacishu: 0,
        orderinfo: null,
        avatarUrl: localStorage.getItem('avatarUrl'),
				//聊天文本框
				content: '',
				isClick: false, //是否可点击发送按钮
				nametitle:'',
				photo:'',
				islike: 0 , // 0未关注 1已关注
				fuwulist:[], // 服务列表
				isCompleted: false,

				initTop: 0,
				isRefresh: false,
				isLoad: false,
				dialogVisible:false
      };
  },
  filters:{
    // 时间
    fifterTime(value) {
				let time = value ? value.replace(/-/g, '/') : '';
				if (time) {
					return resetTime.timeago(new Date(time).getTime());
				}
			}
  },
  computed: {
    ...mapState({
				allConversation: state => state.allConversation,
		}),
    allConversation() {
      return this.$store.state.allConversation;
    },

  },
  watch: {},
  created() {
	},
  mounted() {
    if (this.userID) {
				this.$tim.login({
					userID: 'u' + this.userimID,
					userSig: this.userSig
				});
		}
    // console.log(this.$store.state.allConversation,'对话聊天页面mounted');
    if(this.$route.query.lawyerphone){
      this.lawyerphone = this.$route.query.lawyerphone;
    }


    // 根据用户律师手机号获取订单
		this.$nextTick(()=>{
			this.getorder(); 
			this.refreshData(); // 在顶部时下拉刷新数据
		});

  },
  methods: {
		// 关闭弹窗
		handleClose(){
			this.dialogVisible = false;
		},
		// 服务详情
		toElsePage(item){
			let laywer = JSON.stringify({
				id: this.orderinfo.lawyerId,
				name: this.lawyername,
				photo: this.orderinfo.lsLawyer.photo,
				workyear: this.orderinfo.lsLawyer.workYears
			})
			if(item.name == '文书代写'){
				this.$router.push({
					path:'/falvwenshu?lawid=' + this.orderinfo.lawyerId
				})
			} else {
				this.$router.push({
					path:'/wenls?lawid=' + this.orderinfo.lawyerId
				})
			}
		
		},
    getorder() {
      let that = this;
      let data = {
        userPhone:localStorage.getItem("userimID"),
        lawyerPhone: this.$route.query.lawyerphone,
      }
      apiUrl.orderinfophone(data).then((res) => {
        this.lawyername = res.data.lsLawyer.lawyerName;
        this.orderid = res.data.id;
        this.zhuiwencishu = res.data.zhuiwencishu;
        this.diahuacishu = res.data.diahuacishu;
        this.order = {
          content: res.data.content,
          photos: res.data.photos || '',
          price: res.data.price,
          ordertype: res.data.type, //订单类型
        };

				this.nametitle = res.data.lsLawyer.lawyerName;
        this.orderinfo = res.data;

				this.islike = res.data.lsLawyer.islike;

        console.log(this.orderinfo);

        that.$tim.on(that.TIM.EVENT.SDK_READY, that.sdk_ready); //获取消息列表
				that.sdk_ready();
				if (this.zhuiwencishu <= 0) {
					this.$message.error('您的追问次数已用完');
					this.showTypelist();
				}
          
      })
    },
    sdk_ready() {
				console.log('sdk_ready.....')
				//获取消息列表
				this.initListener(); // 收到新消息
				this.getMsgList();
			},
    // 初始化监听器
    initListener() {
      // 收到新消息
      this.$tim.on(this.TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage);
    },
    //接收 更新消息
			onReceiveMessage({data: messageList}) {
				let that = this;
				console.log('更新消息==', messageList);
				messageList.map(item => {
					that.messageList.push({
						flow: item.flow == 'out' ? 'user' : 'heuser',
						id: that.randomString(),
						type: item.type,
						time: item.time * 1000,
						isPeerRead: item.isPeerRead, //true已读
						userinfo: {
							username: item.nick,
							face: item.avatar
						},
						content: that.resetMsgItem(item)
					})
				})
				setTimeout(() => {
					that.scrollToBottom();
				}, 50)
			},
      // 加载初始页面消息
			getMsgList(type = '') {
				console.log(this.lawyerphone)
				// 打开某个会话时，第一次拉取消息列表
				if (!this.isCompleted) {
					// 如果请求还没回来，又拉，此时做一下防御
					// if (!this.isLoading) {
						// uni.showLoading();
						// this.isLoading = true;
						let list = [];
						let that = this;
						let promise = this.$tim.getMessageList({
							conversationID: 'C2Cl' + this.lawyerphone,
							nextReqMessageID: this.nextReqMessageID,
							count: 30
						});
						promise.then(function(imResponse) {
							console.log(imResponse)
							const messageList = imResponse.data.messageList; // 消息列表。
							const nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
							const isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
							that.nextReqMessageID = nextReqMessageID;
							that.isCompleted = isCompleted;
							// that.isLoading = false;
							// uni.hideLoading();
							if (messageList.length == 0) {
								that.senCustomMessage();
								return;
							}
							messageList.map((item, index) => {
								if (['TIMFileElem', 'TIMVideoFileElem'].includes(item.type)) {
									messageList.splice(index, 1);
									return;
								}
								list.push({
									flow: item.flow == 'out' ? 'user' : 'heuser',
									id: that.randomString(),
									type: item.type,
									time: item.time * 1000,
									isPeerRead: item.isPeerRead, //true已读
									userinfo: {
										username: item.nick,
										face: item.avatar
									},
									content: that.resetMsgItem(item)
								});
							})
							that.messageList = [...list, ...that.messageList];
							if (type == '') {
								setTimeout(() => {
									that.scrollToBottom();
								}, 50)
							}
							console.log(that.messageList)
						});
					// } else {
						// uni.showToast({
						// 	title: '你拉的太快了',
						// 	icon: 'none',
						// 	duration: 500
						// })
					// }
				} else {
					this.$message('没有更多啦');
				}
			},
			// 向上加载刷线
			refreshData(){
				var oTag = this.$refs.pronbit;
        var disX = 0;
        var disY = 0;
        var t = 0;
        var that = this;
        oTag.onmousedown = function(ev) {
          var oEvent = ev || event;
          disX = oEvent.clientX;
          disY = oEvent.clientY;
          oTag.onmousemove = function(ev) {
            var oEvent = ev || event;
            t = oEvent.clientY - disY;
            if (t > 0 && t > 20) {
              that.isRefresh = true;
            }
          };
          oTag.ononmouseout = oTag.onmouseup = function() {
            oTag.onmousemove = null;
            oTag.onmouseup = null;
            that.isRefresh = false;
            if (t > 0 && that.initTop == 0) { // that.initTop == 0 必须在顶部时，才能下拉刷新
              that.getMsgList(1);
            }
          };
          return false; //chrome、ff、IE9
        };

			},

      renderMessageDate(message, index) {
				if (index === 0) {
					let date = new Date(message.time)
					return formatTime.formatTime(date)
				} else {
					if (message.time - this.messageList[index - 1].time > 5 * 60 * 1000) {
						let date = new Date(message.time)
						return formatTime.formatTime(date)
					}
				}
				return '';
			},
      resetMsgItem(item) {
				//重置数据格式
				let content = {};
				if (item.type == 'TIMTextElem') {
					content['title'] = item.payload.text;
				} else if (item.type == 'TIMCustomElem') {
					if (!item.payload.data) {
						return;
					}
					let customData = JSON.parse(item.payload.data);
					if (customData.businessID == 'aftersale') {
						content['title'] = '已申请售后';
						content['reason'] = customData.reason;
						content['desc'] = customData.desc;
					} else if (customData.businessID == 'star') {
						content['title'] = customData.contentStr;
						content['star'] = customData.star;
					} else if (customData.businessID == 'reward') {
						content['money'] = customData.money;
					} else {
						content['title'] = customData.contentStr;
						content['ordertype'] = customData.typeStr;
						content['orderPrice'] = customData.priceStr == '0' ? '免费' : ('￥' + customData.priceStr);
						content['images'] = customData.imgarr ? customData.imgarr.split(',') : [];
					}
					content['businessID'] = customData.businessID;
				} else if (item.type == 'TIMSoundElem') {
					content['soundurl'] = item.payload.url;
					content['soundSecond'] = item.payload.second;
				} else if (item.type == 'TIMImageElem') {
					if (item.payload.imageInfoArray.length <= 0) return;
					item.payload.imageInfoArray.forEach(val => {
						if (val.type == 0) {
							content['imageInfo'] = val;
							if (val.width > 300) {
								val.width = 300;
								val.height = val.height / 300 * val.width;
							}
						}
					})
				}
				return content;
			},
      senCustomMessage() {
				//发送自定义消息
				let that = this;
				let message = this.$tim.createCustomMessage({
					to: 'l' + that.lawyerphone,
					conversationType: that.TIM.TYPES.CONV_C2C,
					payload: {
						data: JSON.stringify({
							businessID: 'zixun',
							contentStr: that.order.content,
							typeStr: that.order.ordertype,
							priceStr: that.order.price == '0' ? '0' : '￥' + that.order.price,
							imgarr: that.order.photos
						})
					}
				});
				this.sendMessage(message)
			},
      sendMessage(message) {
				let that = this;
				// uni.hideLoading();
				let promise = this.$tim.sendMessage(message);
				promise.then(function(imResponse) {
					// 发送成功
					console.log('发送成功');
					let item = imResponse.data.message;
					that.messageList.push({
						flow: item.flow == 'out' ? 'user' : 'heuser',
						id: that.randomString(),
						type: item.type,
						time: item.time * 1000,
						isPeerRead: item.isPeerRead, //true已读
						userinfo: {
							username: item.nick,
							face: item.avatar
						},
						content: that.resetMsgItem(item)
					})
					setTimeout(() => {
						that.scrollToBottom();
					}, 50)
				}).catch(function(imError) {
					// 发送失败
					this.$message.error(imError);
					console.warn('sendMessage error:', imError);
				});
			},
      randomString(len) {
				len = len || 24;
				var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz';
				var maxPos = $chars.length;
				var pwd = '';
				for (let i = 0; i < len; i++) {
					pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
				}
				return pwd;
			},
			// 如果是div 有滚动条  让滚动条滚到最底部
      scrollToBottom() {
				this.$nextTick(()=>{
					var scrollDom = document.getElementById('myDIV');
					scrollDom.scrollTop = scrollDom.scrollHeight
				})
			},
      // 预览图片
      predivImage(src){
        window.open(src,"_blank");
      },
			// 发送文本
			sendTextMessage(){
				console.log('发送')
				//发送文本消息
				if (this.zhuiwencishu == 0) {
					this.content = "";
					this.$message.error('您的追问次数已用完');
					this.showTypelist();
					return;
				}

				if (this.content.trim() !== '') {
					this.isClick = true;
					let data = {
						userPhone: this.userimID, //用户手机号
						lawyerPhone: this.lawyerphone, //律师手机号		
						userType: 0, //用户类型0用户 1律师
						userId: this.userID, //用户id
						content: this.content, //内容
					}
					apiUrl.msgorderphone(data).then(res => {
						if (res.code == 0) {
							this.zhuiwencishu = res.data;
							let message = this.$tim.createTextMessage({
								to: 'l' + this.lawyerphone,
								conversationType: this.TIM.TYPES.CONV_C2C,
								payload: {
									text: this.content
								},
							});
							this.sendMessage(message);
							this.content = "";
						} else {
							this.$message(res.msg);
						}
						this.isClick = false;
					});
				}
			},
			sendMessage(message) {
				let that = this;
				let promise = this.$tim.sendMessage(message);
				promise.then(function(imResponse) {
					// 发送成功
					console.log('发送成功');
					let item = imResponse.data.message;
					that.messageList.push({
						flow: item.flow == 'out' ? 'user' : 'heuser',
						id: that.randomString(),
						type: item.type,
						time: item.time * 1000,
						isPeerRead: item.isPeerRead, //true已读
						userinfo: {
							username: item.nick,
							face: item.avatar
						},
						content: that.resetMsgItem(item)
					})
					setTimeout(() => {
						that.scrollToBottom();
					}, 50)
				}).catch(function(imError) {
					// 发送失败
					this.$message(imError);
					console.warn('sendMessage error:', imError);
				});
			},
			randomString(len) {
				len = len || 24;
				var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz';
				var maxPos = $chars.length;
				var pwd = '';
				for (let i = 0; i < len; i++) {
					pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
				}
				return pwd;
			},
			// 服务列表
			showTypelist() {
				this.dialogVisible = true;
				let data = {
					// ids: "2,3,4,5,6,7,8"
				}
				apiUrl.lsFuwulist(data).then(res => {
					if (res.code == 0) this.fuwulist = res.rows;
				});
			},
			// 扩展消息===========
			// 发送照片
			sendImg(){
				let that = this;
				let message = that.$tim.createImageMessage({
						to: 'l' + that.lawyerphone,
						conversationType: that.TIM.TYPES.CONV_C2C,
						payload: {
							file: document.getElementById('imagePicker'),
						},
						onProgress: function(event) {
							console.log('file uploading:', event)
						}
				});
				that.sendMessage(message);
			},
			tourl(url){
				this.$router.push({
					path: url
				});
				// let path = this.$router.resolve({
				// 	path: url
				// });
				// 打开一个新的页面     
				// window.open(path.href, '_blank')
			},
			// 关注律师，取消律师
			ischeck(){
				let data = {
					lawyerId: this.orderinfo.lsLawyer.id,
					userId: localStorage.getItem("userid"),
				}
				apiUrl.lsLawyerlike(data).then(res => {
					this.islike = !this.islike;
				});
			},
  },

 
  }
</script>
<style lang='scss' >
// @import url(); 引入公共css类
.chatindex{
  // background-color:  #F8F8F8;
  padding: 20px 0 33px;
  .mainbox{
    height: 650px;
    background-color:  #F8F8F8;
    border-radius: 13px;
    padding: 17px 0 84px;
		position: relative;
		.nametitle{
			padding-left: 27px;
			border-bottom: 1px solid #ccc;
			padding-bottom: 25px;
			padding-top: 15px;
			font-size: 20px;
			font-weight: 540;
		}
    .tabsbox{
      height: 40px;
      line-height: 41px;
      border-bottom: 1px solid  #ECECEC;
      padding-left: 67px;
      p{
        font-size: 15px;
        color: #333333;
        margin-right: 53px;
        cursor: pointer;
      }
      .active{
          font-weight: 600;
          color: #305BFE;
          border-bottom: 3px solid #305BFE;
      }
    }
    .textbox{
      // padding: 0 67px;
      // box-sizing: border-box;
    }
  }
  
}
.messagelist{
	height: 400px;
  overflow: auto;
}
.time-lag {
	font-size: 12px;
	text-align: center;
	margin-top:20px;
}
.message-item {
	margin-top: 30px;
	margin-bottom: 30px;
	overflow: hidden;
	display: flex;
  padding: 20px;
  background-color: #F8F8F8;
}

.avatar{
  width: 40px;
	height: 40px;
	margin-right: 20px;
	// flex-shrink: 0;
	// flex-grow: 0;
	border-radius: 50%;
  .avatarimg{
    width: 40px;
		height: 40px;
		border-radius: 50%;
  }
}

// 右侧 自己发的消息
.message-item.self {
	overflow: hidden;
	display: flex;
	justify-content: flex-start;
	flex-direction: row-reverse;
}
.message-item.self .avatar {
	margin-left: 20px;
	margin-right: 0;
}

.content{
    max-width:560px;
		padding:18px;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
		>div:not(:last-child){
			margin:0 0 11px;
		}
		text{
			color:#F07300!important;
		}
	}
  .message-item .message-text {
    background-size: 100% 100%;
    padding:10px 13px 1px 18px;
    font-size: 14px;
    font-weight: 400;
    color: #424242;
    text-align: left!important;
    flex:1;
    background-color: #fff;
    .title{
      margin-bottom:16px;
    }
  }
  .images{
    img{
      width: 128px;
      height: 146px;
      margin:30px 46px 0 0;
    }
  }

  .order-type-msg{
    margin:29px 0 16px;
  }
  .flex.area-between {
    display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
  .order-type-name{
    font-size: 16px;
    color: #939393;
    margin-right: 30px;
  }
  .order-price{
    font-size: 12px;
    color: #FF0000;
  }

  .giveReward{
    font-size: 14px;
    color: #FF0000;
    margin-bottom:16px;
  }
  .evaluate{
    color: #424242;
    >div{
      margin-right:26px;
    }
    margin-bottom:16px;
  }

  .afterSale{
    padding:20px 0 16px;
    border-top:1px solid #F8F8F8;
    >div{
      margin:0 0 20px;
    }
  }

  .gray{
    color:#848282;
  }

  .imageInfo{
    >img{
      max-width:200px;
      max-height:200px;
    }
  }
  .isPeerRead{
		margin:0 10px 0 10px;
		font-size: 12px;
	}
  .selfdirection{
    flex-direction: row-reverse;
  }

	// 发送消息
	.action-box{
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 100;
		// background: #fff;
		border-top: 1px solid #ccc;
		 .action-top {
				padding:10px;
				// backdrop-filter: blur(0.27px);
				background: #F5F5F5;
			}
		 .message-input {
				background: #F2F2F2;
				border-radius: 16px;
				.el-textarea__inner{
					width: 1130px;
					font-size: 12px;
				}
				
			}
			.add-icon-more{
				width:25px;
				height:25px;
				margin-left:10px;
			}
			.send-message-btn{
				cursor: pointer;
				font-size: 12px;
				width:80px;
				height:40px;
				line-height: 40px;
				color:#FFFFFF;
				background: #2979FF;
				border-radius: 5px;
				text-align: center;
				margin-left:10px;
			}
			.noClick {
				pointer-events: none;
			}
			.imgtop{
				padding-top: 10px;
				.zpimg{
					cursor: pointer;
					width: 20px;
					height: 20px;
					margin-right: 10px;
				}
				.zhaopian{
					margin-left: 10px;
					margin-right: 10px;
					width: 20px;
					height: 20px;
					position: relative;
					.inputimg{
						width: 20px;
						height: 20px;
						position: absolute;
						cursor: pointer;
						outline: medium none;
						filter: alpha(opacity=0);
						-moz-opacity: 0;
						opacity: 0;
						top: 0;
						left: 0px;
					}
				}
				
			}
			
	}

	// 服务列表弹窗
	.chooseindex{
		// padding:48px 36px;
		>.item{
			margin:0 0 42px;
			cursor: pointer;
			>img{
				width: 40px;
				height: 40px;
				margin-right:8px;
			}
			>div{
				flex:1;
			}
			.name{
				color: #181818;
			}
			.price{
				color: #FF0000;
			}
			.introduce{
				font-size: 22rpx;
				color: #52504F;
				margin:10rpx 0 0;
			}
		}
	}

	.el-dialog--center .el-dialog__body{
		max-height: 500px;
    overflow: auto;
	}


</style>